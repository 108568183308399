import React, { useState } from "react";
import footer_wave from "../../asset/footer_wave.png";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig/firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import contact_cover from "../../asset/cover/contact_cover.svg";
import { Helmet } from "react-helmet";
import white_background_wave from "../../asset/white-background-wave-2.png";

export default function Contact() {
  const [contactUs, setcontactUs] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setcontactUs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "users"), { contactUs })
      .then((res) => {
        toast.success("Message sent successfully!");
      })
      .catch((error) => {
        toast.error("Failed to send message!");
      });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact - UpcomingVerse</title>
        <link rel="canonical" href="https://www.upcomingverse.ai/contact" />
        <meta name="author" content="Upcomingverse" />
        <meta name="category" content="Contact" />
        <meta
          name="title"
          content="Let's Talk AI Solutions - UpcomingVerse"
        />
        <meta
          name="description"
          content="Reach out to UpcomingVerse for swift connections and excellent AI-based services. We are always ready to serve you."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <ToastContainer />
      <section className="contact_cover">
        <div class="container">
          <div class="row my-3">
            <div class="col-md-6 p-3 center" style={{ alignSelf: "center" }}>
              <div className="p-3 contact_cover_title">Let's Talk:</div>
              <div className="p-3 contact_cover_details">
                <p>Reach Out for a swift Connection!</p>
              </div>
            </div>
            <div class="col-md-6 p-3">
              <div class="text-center m-auto center product-img p-3">
                <img src={contact_cover} class="img-fluid" alt="product" />
              </div>
            </div>
          </div>
        </div>
        <img
          src={white_background_wave}
          class="bottom-wave_services"
          alt="cover"
        />
      </section>

      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6 p-5">
              <h1 className="text-center">Contact Us</h1>
              <h3 className="py-4 text-center">
                We are always ready to serve you!
              </h3>
            </div>

            <div className="col-md-6">
              <div className="contact_form text-center m-auto">
                <div className="contact-form">
                  <form id="contact-form" onSubmit={handleSubmit}>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      onChange={handleInputChange}
                      required
                    />
                    <br />
                    <input
                      id="email"
                      type="email"
                      name="email"
                      className="form-control"
                      onChange={handleInputChange}
                      placeholder="Your Email"
                      required
                    />
                    <br />
                    <textarea
                      id="message"
                      type="text"
                      name="message"
                      className="form-control"
                      placeholder="Message"
                      onChange={handleInputChange}
                      rows="4"
                      required
                    ></textarea>
                    <br />
                    <input
                      type="submit"
                      name="submit"
                      className="form-control btn btn-info submit"
                      value="Send Message"
                      required
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={footer_wave}
          alt=""
          className="bottom-wave_footer"
        />
      </section>
    </div>
  );
}
