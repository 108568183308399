import React from "react";
import about_us from "../../asset/about_us.svg";
import about_cover from "../../asset/cover/about_cover.svg";
import footer_wave from "../../asset/footer_wave.png";
import white_background_wave from "../../asset/white-background-wave-2.png";
import { Helmet } from "react-helmet";

export default function About() {
  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>About - UpcomingVerse</title>
          <link rel="canonical" href="https://www.upcomingverse.ai/about" />
          <meta name="author" content="upcomingverse" />
          <meta name="category" content="About" />
          <meta
            name="title"
            content="About Pioneering AI Solutions and Software
          Development - UpcomingVerse"
          />
          <meta
            name="description"
            content="Discover UpcomingVerse, a dynamic software development and product company dedicated to pioneering cutting-edge solutions in Generative AI, Machine Learning, Data Science, and Data Engineering. Explore our comprehensive suite of services and expert developers to augment your IT capabilities."
          />
          <meta name="robots" content="index, follow" />
      </Helmet>
      <section className="about_cover">
        <div class="container">
          <div class="row my-3">
            <div class="col-md-8 p-3 center" style={{ alignSelf: "center" }}>
              <div className="p-3 about_cover_title">
                Crafting Tomorrow, Today:
              </div>
              <div className="p-3 about_cover_details">
                <p>
                  Get To Know Our Passion for Innovation and Dedication To
                  Excellence!
                </p>
              </div>
            </div>
            <div class="col-md-4 p-3">
              <div class="text-center m-auto center product-img p-3">
                <img src={about_cover} class="img-fluid" alt="product" />
              </div>
            </div>
          </div>
        </div>
        <img
          src={white_background_wave}
          class="bottom-wave_services"
          alt=""
        />
      </section>
      <section id="about">
        <div class="container">
          <div class="row my-3">
            <div class="col-md-6 p-3">
              <div class="text-center   about-img">
                <img
                  src={about_us}
                  width="300px"
                  height="300px"
                  alt="product"
                />
              </div>
            </div>
            <div class="col-md-6 p-3">
              <h2 class="p-3 text-center"></h2>
              <h1 class="py-2 about_title">About Us</h1>
              <p class="py-2">
                UpcomingVerse is a dynamic software development and product
                company dedicated to pioneering cutting-edge solutions in the
                realms of Generative AI, Machine Learning, Data Science, and
                Data Engineering and more. We go beyond conventional boundaries
                to bring you a comprehensive suite of services, including
                dedicated resources and expert developers to augment your IT
                capabilities.
              </p>
              <br />
            </div>
          </div>
        </div>
        <img
          src={footer_wave}
          alt=""
          className="bottom-wave_footer"
        />
      </section>{" "}
    </div>
  );
}
