import React from "react";
import undraw_progressive_app_m from "../../asset/undraw_progressive_app_m-9-ms.svg";
import white_background_wave from "../../asset/white-background-wave-2.png";
import product_cover from "../../asset/cover/product_cover.svg";
import footer_wave from "../../asset/footer_wave.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Product() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products - UpcomingVerse</title>
        <link rel="canonical" href="https://www.upcomingverse.ai/product" />
        <meta name="author" content="upcomingverse" />
        <meta name="category" content="AI-Products" />
        <meta
          name="title"
          content="Products - UpcomingVerse"
        />
        <meta
          name="description"
          content="Explore the future with our AI-powered products—intelligent solutions for enhanced efficiency and seamless experiences. Elevate your tasks effortlessly."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section className="product_cover">
        <div class="container">
          <div class="row my-3">
            <div class="col-md-6 p-3 center" style={{ alignSelf: "center" }}>
              <div className="p-3 product_cover_title">
                Discover The Future:
              </div>
              <div className="p-3 product_cover_details">
                <p>
                  Transformative AI at Your Fingertips, Explore Our Innovative
                  Products!
                </p>
              </div>
            </div>
            <div class="col-md-6 p-3">
              <div class="text-center m-auto center product-img p-3">
                <img src={product_cover} class="img-fluid" alt="product" />
              </div>
            </div>
          </div>
        </div>
        <img
          src={white_background_wave}
          alt=""
          className="bottom-wave"
        />
      </section>
      <section id="product">
        <div class="container">
          {/* <h2 class="text-center p-2">PRODUCT</h2>
          <h1 class="section-title-line text-center"/> */}
          <div class="row my-3">
            <div class="col-md-6 p-3">
              <div class="text-center m-auto center product-img">
                <img
                  src={undraw_progressive_app_m}
                  width="300px"
                  height="300px"
                  alt="product"
                />
              </div>
            </div>
            <div class="col-md-6 p-3">
              <h3 class="p-2 text-center">
                Influencer Marketing Analytics Tool
              </h3>
              <p class="text-center">(coming soon)</p>
              <h5 class="p-2">
                Discover a seamless platform where brands and influencers unite
                to create stunning campaigns. Brands can effortlessly share
                campaign details, while influencers bid to secure brand deals.
                Monitor the analytics and engagement generated by influencers to
                elevate your campaigns. Join us and power up your brand's
                influence today!
              </h5>
              <div class="product-btn py-2">
                <Link to={"/contact"} class="btn btn-info">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img
          src={footer_wave}
          alt=""
          className="bottom-wave_footer"
        />
      </section>
    </div>
  );
}
