import React from "react";
import undraw_metrics_re_6g90 from "../../asset/undraw_metrics_re_6g90.svg";
import undraw_robotics_kep0 from "../../asset/undraw_robotics_kep0.svg";
import undraw_developer_activity_re_39tg from "../../asset/undraw_developer_activity_re_39tg.svg";
import undraw_proud_coder_re_exuy from "../../asset/undraw_proud_coder_re_exuy.svg";
import undraw_cloud_files_wmo8 from "../../asset/undraw_cloud_files_wmo8.svg";
import undraw_flutter_dev_wvqj from "../../asset/undraw_flutter_dev_wvqj.svg";
import footer_wave from "../../asset/footer_wave_1.png";
import service_cover from "../../asset/cover/service_cover.svg";
import nonwhite_background_wave from "../../asset/nonwhite-background-wave-2 - Copy.png";
import computer_vision from "../../asset/computer_vision.svg";
import staff_augmentation from "../../asset/staff_augmentation.svg";
import { Helmet } from "react-helmet";
import Slider from "react-slick";

export default function Service() {
  const items = [
    {
      title: "Data Science",
      description:
        "From data collection to deep learning, we transform information into actionable insights with expertise in analytics and machine learning",
      image: undraw_metrics_re_6g90,
    },
    {
      title: "Generative AI",
      description:
        "Deeply versed in generative AI, we specialize in creating models for chatbots, text, image generation, and beyond.",
      image: undraw_robotics_kep0,
    },
    {
      title: "Data Engineering",
      description:
        "End-to-end data engineering solutions: collect, store, and process data seamlessly. Experts in ETL, ELT, database migration, and beyond.      ",
      image: undraw_developer_activity_re_39tg,
    },
    {
      title: "Web Development",
      description:
        "Comprehensive Web Development Services: Crafting Seamless Online Experiences from Concept to Launch. Specializing in Front-End and Back-End Development, Responsive Design, and Scalable Solutions",
      image: undraw_proud_coder_re_exuy,
    },

    {
      title: "Mobile Application Development",
      description:
        "Transform your business with top-notch mobile apps. Expert developers craft engaging iOS and Android applications for a seamless user experience and broad market impact.",
      image: undraw_flutter_dev_wvqj,
    },
    {
      title: "Cloud Services",
      description:
        "Leverage cloud power for scalable, secure solutions. Streamline operations, enhance collaboration, and achieve cost-effective efficiency with our cloud services.",
      image: undraw_cloud_files_wmo8,
    },
    {
      title: "Computer Vision",
      description:
        "Unlock the power of computer vision—specialized services for image and video analysis. Advanced algorithms for automation, accuracy, and informed decision-making.",
      image: computer_vision,
    },
    {
      title: "Staff Augmentation",
      description:
        "Scale your project seamlessly with our tailored staff augmentation services, providing flexible expertise for meeting project goals and timelines.",
      image: staff_augmentation,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const imageStyle = {
    transition: "transform 0.1s",
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.style.transform = "scale(1.05)";
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.transform = "scale(1)";
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services - Upcomingverse</title>
        <link rel="canonical" href="https://www.upcomingverse.ai/service" />
        <meta name="author" content="upcomingverse" />
        <meta name="category" content="Services" />
        <meta
          name="title"
          content="Services - Upcomingverse"
        />
        <meta
          name="description"
          content="Explore a Spectrum of Dynamic Solutions Tailored to Fuel Your Progress! UpcomingVerse provides AI-based solutions, services, and products including Data Science, Generative AI, Data Engineering, Web Development, Mobile Application Development, Cloud Services, Computer Vision, and Staff Augmentation."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section className="service_cover">
        <div class="container">
          <div class="row my-3">
            <div class="col-md-8 p-3 center" style={{ alignSelf: "center" }}>
              <div className="p-3 service_cover_title">
                Driving Success Through Expertise:
              </div>
              <div className="p-3 service_cover_details">
                <p>
                  Explore a Spectrum of Dynamic Solutions Tailored to Fuel Your
                  Progress!
                </p>
              </div>
            </div>
            <div class="col-md-4 p-3">
              <div class="text-center m-auto center product-img p-3">
                <img src={service_cover} class="img-fluid" alt="product" />
              </div>
            </div>
          </div>
        </div>
        <img
          src={nonwhite_background_wave}
          class="bottom-wave_services"
          alt=""
        />
      </section>
      <section id="services">
        <div className="container-fluid">
          <h2 class="text-center p-2">SERVICES</h2>
          <h1 class="section-title-line text-center"></h1>
          <div className="row p-4 my-3">
            <div className="col-lg-12 p-1">
              <Slider {...settings}>
                {items.map((item, index) => (
                  <div key={index} className="p-3">
                    <div
                      className="card card-service m-auto "
                      style={imageStyle}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        className="card-img-top p-2 "
                        src={item.image}
                        width="200"
                        height="200"
                        alt="Card cap"
                      />
                      <div className="card-body">
                        <h3 className="card-title text-center">{item.title}</h3>
                        <p className="card-text">{item.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <img src={footer_wave} alt='' class="bottom-wave_footer" />
      </section>
    </div>
  );
}
