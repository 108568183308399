import React, { useState, useRef } from "react";
import career_hiring from "../../asset/career_hiring.svg";
import { db } from "../FirebaseConfig/firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import S3 from "react-aws-s3";
import career_cover from "../../asset/cover/career_cover.svg";
import footer_wave from "../../asset/footer_wave.png";
import white_background_wave from "../../asset/white-background-wave-2.png";
import { Helmet } from "react-helmet";
window.Buffer = window.Buffer || require("buffer").Buffer;

export default function Career() {
  const [apply, setApply] = useState({
    first_name: "",
    last_name: "",
    email: "",
    resume: "",
    desiganation: "",
    year_of_experiance: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setApply((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  const resumeUpload = (e) => {
    e.preventDefault();
    console.log(e.target.files[0].name);
    const ReactS3Client = new S3(config);
    const newFileName = e.target.files[0].name;
    ReactS3Client.uploadFile(e.target.files[0], newFileName)
      .then((data) => {
        setApply({
          ...apply,
          resume: data.location,
        });
        toast.success("Resume upload successfully!");
      })
      .catch((err) => console.error(err));
  };

  const submit = () => {
    addDoc(collection(db, "career"), { apply })
      .then((res) => {
        toast.success("Apply successfully!");
      })
      .catch((error) => {
        toast.error("Something went wrong!");
      });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Career - Upcomingverse</title>
        <link rel="canonical" href="https://www.upcomingverse.ai/career" />
        <meta name="author" content="upcomingverse" />
        <meta name="category" content="Career" />
        <meta
          name="title"
          content="Shape Tomorrow with Us - UpcomingVerse"
        />
        <meta
          name="description"
          content="Shape Tomorrow, Join Us! Embark on a journey where innovation meets passion! Join our dynamic team, where every day is an adventure. We're seeking individuals ready to grow and thrive. Your next chapter starts here!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <ToastContainer />
      <section className="career_cover">
        <div class="container">
          <div class="row my-3">
            <div class="col-md-8 p-3 center" style={{ alignSelf: "center" }}>
              <div className="p-3 career_cover_title">
                Your Future Begins Here:
              </div>
              <div className="p-3 career_cover_details">
                <p>Explore Opportunities with Us!</p>
              </div>
            </div>
            <div class="col-md-4 p-3">
              <div class="text-center m-auto center product-img p-3">
                <img src={career_cover} class="img-fluid" alt="product" />
              </div>
            </div>
          </div>
        </div>
        <img
          src={white_background_wave}
          class="bottom-wave_services"
          alt=""
        />
      </section>
      <section id="career">
        <div class="container">
          {/* <h1 class="text-center p-3">Career</h1>
          <h1 class="section-title-line text-center"></h1> */}
          <div class="row my-3">
            <div class="col-md-6 p-3">
              <div class="text-center   career-img">
                <img
                  src={career_hiring}
                  width="300px"
                  height="300px"
                  alt="product"
                />
              </div>
            </div>
            <div class="col-md-6 p-3">
              {/* <h2 class="p-3 text-center"></h2> */}
              <h1 class="py-2 career_title">Shape Tomorrow, Join Us!</h1>
              <p class="py-2">
                Embark on a journey where innovation meets passion! Join our
                dynamic team, where every day is an adventure. We're seeking
                individuals ready to grow and thrive. Your next chapter starts
                here!
              </p>
              <p>
                Mail your resume along with short description at{" "}
                <span className="carerr_mail">
                  <strong>career@upcomingverse.ai</strong>
                </span>
              </p>

              {/* <p
                className="py-2 text-center"
                style={{ textAlign: "end", cursor: "pointer" }}
              >
                <button
                  type="button"
                  class="btn btn-primary "
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Apply Here !
                </button>
              </p> */}

              <br />
            </div>
          </div>
        </div>
        <img
          src={footer_wave}
          alt=""
          className="bottom-wave_footer"
        />
      </section>{" "}
      {/* model */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Job Application
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form id="contact-form" onSubmit={submit}>
              <div class="modal-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="recipient-first_name" class="col-form-label">
                      First Name:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="first_name"
                      onChange={handleInputChange}
                      id="recipient-first_name"
                      required
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="recipient-last_name" class="col-form-label">
                      Last Name:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="last_name"
                      onChange={handleInputChange}
                      id="recipient-last_name"
                      required
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="recipient-email" class="col-form-label">
                      Email:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      onChange={handleInputChange}
                      id="recipient-email"
                      required
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label for="message-text" class="col-form-label">
                      Resume:
                    </label>
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        name="resume"
                        onChange={resumeUpload}
                      />
                      <label class="custom-file-label" for="inputGroupFile01">
                        Choose file
                      </label>
                    </div>{" "}
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="recipient-desiganation" class="col-form-label">
                      Desiganation:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="desiganation"
                      onChange={handleInputChange}
                      id="recipient-desiganation"
                      required
                    />
                  </div>

                  <div class="form-group col-md-6">
                    <label
                      for="recipient-year_of_experiance"
                      class="col-form-label"
                    >
                      Year of Experience:
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      name="year_of_experiance"
                      onChange={handleInputChange}
                      id="recipient-year_of_experiance"
                      required
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="message-text" class="col-form-label">
                    Message:
                  </label>
                  <textarea
                    class="form-control"
                    onChange={handleInputChange}
                    name="message"
                    id="message-text"
                    required
                  ></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <input
                  type="submit"
                  name="submit"
                  className="btn btn-info submit"
                  value="Apply"
                  required
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
