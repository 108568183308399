import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCcqjj_6QWDArT1vfCtMa5h-v6zsPvSVFY",
  authDomain: "upcomingverse.firebaseapp.com",
  projectId: "upcomingverse",
  storageBucket: "upcomingverse.appspot.com",
  messagingSenderId: "874439687398",
  appId: "1:874439687398:web:a15a8d94dba1106c074f27"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
