import React from "react";
import undraw_firmware_re_fgdy from "../../asset/undraw_interview_re_e5jn.svg";
import white_background_wave from "../../asset/white-background-wave-2.png";
import "react-alice-carousel/lib/alice-carousel.css";
import undraw_metrics_re_6g90 from "../../asset/undraw_metrics_re_6g90.svg";
import undraw_robotics_kep0 from "../../asset/undraw_robotics_kep0.svg";
import undraw_developer_activity_re_39tg from "../../asset/undraw_developer_activity_re_39tg.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import nonwhite_background_wave from "../../asset/nonwhite-background-wave-2 - Copy.png";
import pythonLogo from "../../asset/technologies_logo/python-logo.png";
import reactLogo from "../../asset/technologies_logo/react-logo.png";
import androidLogo from "../../asset/technologies_logo/android.png";
import awsLogo from "../../asset/technologies_logo/aws-logo.png";
import azureLogo from "../../asset/technologies_logo/azure-logo.png";
import bootstrapLogo from "../../asset/technologies_logo/bootstrap-logo.png";
import djangoLogo from "../../asset/technologies_logo/django-logo.png";
import openaiLogo from "../../asset/technologies_logo/openai-logo.png";
import opencvLogo from "../../asset/technologies_logo/opencv-logo.png";
import tensorflowLogo from "../../asset/technologies_logo/tensorflow-logo.png";
import footer_wave from "../../asset/footer_wave_1.png";
import why_choose_us from "../../asset/why_choose_us/why_choose_us.jpg";
import happy_client from "../../asset/stats/1.png";
import years_experience from "../../asset/stats/2.png";
import total_projects from "../../asset/stats/3.png";
import vision_image from "../../asset/vision_image.svg";
import { Helmet } from "react-helmet";

export default function Home() {
  const technologiesSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    arrows: true,
    width: "80%",
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const imageStyle = {
    transition: "transform 0.1s",
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.style.transform = "scale(1.05)";
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.transform = "scale(1)";
  };

  const technologies = [
    {
      image: djangoLogo,
    },
    {
      image: openaiLogo,
    },
    {
      image: reactLogo,
    },
    {
      image: pythonLogo,
    },
    {
      image: azureLogo,
    },
    {
      image: opencvLogo,
    },
    {
      image: awsLogo,
    },
    {
      image: bootstrapLogo,
    },
    {
      image: androidLogo,
    },
    {
      image: tensorflowLogo,
    },
  ];

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>UpcomingVerse - AI Powered Excellence, Refining Possibilities</title>
        <link rel="canonical" href="https://www.upcomingverse.ai" />
        <meta name="author" content="Upcomingverse" />
        <meta name="category" content="Home" />
        <meta
          name="title"
          content="UpcomingVerse - AI Powered Excellence, Refining Possibilities"
        />
        <meta
          name="description"
          content="UpcomingVerse is an AI based solutions, services and products company. Our team of experts leverages advanced AI methodologies to tailor products and services that best suit your unique requirements."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section id="banner">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 p-4 m-auto ">
              <h1 class="py-2">
                Elevating Your Experience with AI-Powered Solutions
              </h1>
              <p class="py-2">
                Our team of experts leverages advanced AI methodologies to
                tailor products and services that best suit your unique
                requirements.
              </p>

              <br />
              <Link
                to={"/contact"}
                class="btn btn-info center py-2 text-center"
              >
                Get In Touch!
              </Link>
            </div>

            <div class="col-lg-6 text-center m-auto">
              <img
                src={undraw_firmware_re_fgdy}
                class="img-fluid p-5"
                alt="image"
              />
              <br />
              <div>
                <a
                  target="_blank"
                  href="https://www.instagram.com/upcomingverse.ai/"
                  class="fa fa-instagram"
                ></a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/upcomingverse"
                  class="fa fa-linkedin"
                ></a>
              </div>
            </div>
          </div>
        </div>
        <img src={white_background_wave} class="bottom-wave" alt="" />
      </section>

      <section id="ourVision">
        <div class="container">
          <div class="row my-3">
            <div class="col-md-5 p-3">
              <div class="text-center m-auto center product-img">
                <img
                  src={vision_image}
                  width="300px"
                  height="300px"
                  alt="product"
                />
              </div>
            </div>
            <div class="col-md-7 p-3 ">
              <h4 className="p-3 vision_title">
                <strong className="text-center">
                  Our Vision: Transforming Ideas into Reality
                </strong>
              </h4>
              <h5 class="p-3">
                In a world of constant change, innovation is not a choice; it's
                a necessity. UpcomingVerse envisions a future where technology
                seamlessly integrates with human potential. We are driven by the
                passion to create solutions that not only address current
                challenges but anticipate the needs of tomorrow.
              </h5>
            </div>
          </div>
        </div>
        <img
          src={nonwhite_background_wave}
          class="bottom-wave_services"
          alt=""
        />
      </section>

      <section id="stats">
        <div>
          <div class="container">
            <h2 class="text-center p-2">Our Success in Numbers</h2>
            <h1 class="section-title-line text-center"></h1>

            <div class="row p-2 my-3 mx-auto">
              <div class="col-md-4 p-2 my-4">
                <div class=" card-stats text-center m-3">
                  <img src={happy_client} alt=">YEARS OF INDUSTRY EXPERIENCE" />
                  <h5 className="mt-3">5+ </h5>
                  <h5>YEARS OF INDUSTRY EXPERIENCE</h5>
                </div>
              </div>
              <div class="col-md-4 p-2 my-4">
                <div class=" card-stats text-center m-3">
                  <img src={years_experience} alt="year of experiance" />
                  <h5 className="mt-3">7+ </h5>
                  <h5>SUCESSFUL PROJECTS DELIVERED</h5>
                </div>
              </div>
              <div class="col-md-4 p-2 my-4">
                <div class=" card-stats text-center m-3">
                  <img src={total_projects} alt="total_projects" />
                  <h5 className="mt-3">95%+ </h5>
                  <h5>
                    CLIENT SATISFACTION <br /> RATE
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <img
            src={white_background_wave}
            alt=""
            className="bottom-wave"
          />
        </div>
      </section>

      <section id="ourClients">
        <div class="container">
          <div class="row my-3">
            <div class="col-md-5 p-3">
              <div class="text-center m-auto center product-img">
                <img src={why_choose_us} className="img-fluid" alt="product" />
              </div>
            </div>
            <div class="col-md-7 p-3 ">
              <h4 className="pl-3 vision_title">
                <strong className="text-center">Why Choose Us?</strong>
              </h4>
              <h5 class="p-3">
                <strong>Innovation at the Core:</strong> We boast a 95% client
                satisfaction rate in delivering innovative solutions that stay
                ahead of industry trends. <br /> <br />
                <strong>Collaborative Approach:</strong> We've successfully
                collaborated on multiple projects, ensuring that our solutions
                align with our clients' business goals.
                <br />
                <br />
                <strong>Efficiency and Timeliness:</strong> Operating on an
                agile development methodology, we've delivered high-quality
                solutions within agreed timelines.
                <br />
                <br />
                <strong>Goal-Oriented Solutions:</strong> We create solutions
                with your success in mind, aligning closely with your business
                goals to ensure every project contributes meaningfully to your
                growth and achievements.
              </h5>
            </div>
          </div>
        </div>
        <img src={nonwhite_background_wave} class="bottom-wave_services" />

        {/* Testimonials */}
      </section>

      {/* <section id="testimonials">
        <div
          className="container-fluid "
          style={{ textAlign: "-webkit-center" }}
        >
          <h1 class="text-center p-2">Testimonials </h1>
          <h1 class="section-title-line text-center"></h1>
          <div className="row  my-3" style={{ width: "80%" }}>
            <div className="col-lg-12 p-3">
              <Slider {...settings}>
                {items.map((item, index) => (
                  <div key={index} className="p-3">
                    <div
                      className="card card-service m-auto text-center"
                      style={imageStyle}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        className="card-img-top p-2"
                        src={item.image}
                        width="200"
                        height="200"
                        alt="Card cap"
                        style={imageStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      />
                      <div className="card-body">
                        <h3 className="card-title">{item.title}</h3>
                        <p className="card-text">{item.description}</p>
                        <div className="card-overlay"></div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section> */}

      <section id="technologies">
        <h2 class="text-center p-2">Technologies</h2>
        <h1 class="section-title-line text-center"></h1>
        <div
          className="p-3  mb-5"
          width={"100%"}
          style={{ textAlign: "-webkit-center" }}
        >
          <div className="p-3" style={{ maxWidth: "80%" }}>
            <Slider {...technologiesSettings}>
              {technologies.map((item, index) => (
                <div key={index} className="p-3">
                  <div
                    style={imageStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="p-3"
                  >
                    <img
                      className=" p-2 "
                      src={item.image}
                      height="150"
                      alt="Card cap"
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <img src={footer_wave} class="bottom-wave_footer" alt="" />
      </section>
    </div>
  );
}
