import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../asset/logo.png";
import { NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function Header() {
  const location = useLocation();
  const [isSticky, setSticky] = useState(false);
  const [activeItem, setActiveItem] = useState("HOME");
  const [isComingSoonHovered, setComingSoonHovered] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname;
    setActiveItem(currentPath);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleNavbarCollapse = () => {
    const toggler = document.querySelector(".fa.fa-close");
    const isMobileView = window.innerWidth <= 768; // Adjust the breakpoint as needed
    if (isMobileView) {
      toggler.click();
    }
  };

  const handleItemClick = (data) => {
    setActiveItem(data);
    if (data !== "/company") {
      handleNavbarCollapse();
    }
  };

  const handleComingSoonHover = () => {
    setComingSoonHovered(true);
    handleItemClick("/company");
  };

  const handleComingSoonLeave = () => {
    setComingSoonHovered(false);
    const currentPath = location.pathname;
    setActiveItem(currentPath);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className={`top-bar mt-0 `}>
        <div className="topline p-2">
          <a href="#" className="fa fa-phone px-3">
            <span className="px-2">+91-9303386620</span>
          </a>
          <a href="#" className="fa fa-envelope px-3">
            <span className="px-2">info@upcomingverse.ai</span>
          </a>
        </div>
      </div>

      <section id="nav-bar">
        <nav className={`navbar navbar-expand-lg ${isSticky ? "sticky" : ""}`}>
          <Link className="navbar-brand" to="/">
            <img src={logo} width="50" height="50" alt="logo" />
            UpcomingVerse.ai
          </Link>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="close">
              <i className="fa fa-close"></i>
            </span>
            <span className="navbar-toggler-icon">
              <i className="fa fa-bars"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className={`nav-item ${activeItem === "/" ? "active" : ""}`}>
                <Link
                  id="s1"
                  className="nav-link"
                  to="/"
                  onClick={() => handleItemClick("/")}
                >
                  HOME
                </Link>
              </li>
              <li
                className={`nav-item ${
                  activeItem === "/product" ? "active" : ""
                }`}
              >
                <Link
                  id="s3"
                  className="nav-link"
                  to="/product"
                  onClick={() => handleItemClick("/product")}
                >
                  PRODUCT
                </Link>
              </li>
              <li
                className={`nav-item ${
                  activeItem === "/service" ? "active" : ""
                }`}
              >
                <Link
                  id="s3"
                  className="nav-link"
                  to="/service"
                  onClick={() => handleItemClick("/service")}
                >
                  SERVICE
                </Link>
              </li>

              <li
                className={`nav-item ${
                  activeItem === "/company" ? "active" : ""
                }`}
                onMouseOver={
                  document.querySelector(".fa.fa-close")
                    ? handleComingSoonHover
                    : null
                }
                onMouseLeave={
                  document.querySelector(".fa.fa-close")
                    ? handleComingSoonLeave
                    : null
                }
              >
                <NavDropdown
                  title={`COMPANY`}
                  id="nav-dropdown"
                  show={isComingSoonHovered}
                >
                  <LinkContainer to="/about">
                    <NavDropdown.Item
                      eventKey="service1"
                      className="custom-nav-item"
                      onClick={() => handleItemClick("/about")}
                    >
                      ABOUT
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/career">
                    <NavDropdown.Item
                      eventKey="service2"
                      className="custom-nav-item"
                      onClick={() => handleItemClick("/career")}
                    >
                      CAREER
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </li>

              <li
                className={`nav-item ${
                  activeItem === "/contact" ? "active" : ""
                }`}
              >
                <Link
                  id="s4"
                  className="nav-link"
                  to="/contact"
                  onClick={() => handleItemClick("/contact")}
                >
                  CONTACT US
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </section>
    </div>
  );
}
