import React from "react";
import { Link } from "react-router-dom";


export default function Footer() {
  return (
    <div>
      <section id="footer">
        <div id="footer-wrap">
          <footer
            className="footer p-4"
            style={{ width: "80%", margin: "0 auto" }}
          >
            <div className="top-footer">
              <div className="row">
                <div className="col-md-3 px-5 py-3">
                  <h4>Quick Links</h4>
                  <ul className="quickLinks">
                    <li>
                      <Link
                        to={"/"}
                        style={{
                          color: "white",
                          textDecoration: "none",
                          marginTop: "auto",
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/product"}
                        style={{
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        Product
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/service"}
                        style={{
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/contact"}
                        style={{
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 px-5 py-3">
                  <h4>About</h4>
                  <p>
                  Pioneering software development and products. Transforming businesses with innovative solutions. Your dedicated partner in advancing technology and achieving success.
                  </p>
                </div>
                <div className="col-md-3 px-5 py-3">
                  <div>
                    <h4>Social Media</h4>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/upcomingverse"
                      className="fa fa-linkedin p-2"
                    />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/upcomingverse.ai/"
                      className="fa fa-instagram p-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-copyright text-center py-2">
              © 2023 Copyright:
              <a
                href="http://upcomingverse.ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                upcomingverse.ai
              </a>
            </div>
          </footer>
        </div>
      </section>
    </div>
  );
}
