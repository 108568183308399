import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Componenet/Home/Home";
import "./App.css";
import Header from "./Componenet/Header/Header";
import Product from "./Componenet/Product/Product";
import Service from "./Componenet/Service/Service";
import Contact from "./Componenet/ContactUS/Contact";
import Footer from "./Componenet/Footer/Footer";
import About from "./Componenet/About/About";
import Career from "./Componenet/Career/Career";
import ReactGA from "react-ga4";
import { useEffect } from "react";
ReactGA.initialize('G-LLQ0L2237N');


function App() {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, );

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home  />}></Route>
          <Route path="/product" element={<Product />}></Route>
          <Route path="/service" element={<Service />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/career" element={<Career />}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
